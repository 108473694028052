import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import { useGraphQL } from '@/data';
import {
	GatewaysPublicRead_StorePaymentQuery,
	GatewaysPublicRead_StorePaymentQueryVariables,
} from '@/generated/graphql';
import { clientPaymentsReadGQL } from '@/pages/dashboard/commerce/multiPayments/utils';
import ClientPaymentSelection from '@/pages/dashboard/commerce/payment/clientPayment';
import { GatewaysPublicRead_StorePayment } from '@/pages/p/store/payment/utils';
import { fetchCards, surchargeFeeAtom } from '@/pages/settings/cards';
import { GatewayBase, Order, Payment, QueryClientPaymentsReadArgs } from '@/types/schema';
import { ArrowBackIos as ArrowBackIosIcon } from '@mui/icons-material';
import { Box, Button, Chip, Collapse, Fade, FormControlLabel, Radio, RadioGroup, Stack, Tooltip } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useAtomValue } from 'jotai/index';
import { isEmpty, isString, round, toLower } from 'lodash-es';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsyncEffect } from 'rooks';
import CardTypeOptions from '../payment/cardType';

export default function PaymentMethod( {
	required,
	cancelMethod,
	confirmMethod,
	invoice,
	selectedClientPayment,
	setSelectedClientPayment,
	cardType,
	setCardType,
	totalRemaining,
}: {
	required?: boolean, // inside /client
	cancelMethod?: () => void,
	confirmMethod?: ( methodObject: any ) => Promise<void> | void,
	invoice: Order,
	selectedClientPayment?: Payment,
	setSelectedClientPayment?: ( payment: Payment ) => void,
	cardType?: string,
	setCardType?: ( cardType: string ) => void,
	totalRemaining: number
} ) {
	const { gateway: _gateway, type, companyLocation, company, metadata, client } = invoice;
	const { enqueueSnackbar } = useSnackbar();
	const router = useRouter();
	const { t } = useTranslation();
	
	const gateway = _gateway || companyLocation?.gateway || company.mainPayment;
	const [ paymentMethod, setPaymentMethod ] = useState( '' );
	const [ cards, setCards ] = useState( [] );
	const [ cardId, setCardId ] = useState( '' );
	const [ paymentGateway, setPaymentGateway ] = useState<GatewayBase | null>( null );
	
	const surchargeFeePercent = useAtomValue( surchargeFeeAtom );
	const cardFeePercent = company.metadata?.cardFee;
	const includeDebitCardFee = company.metadata?.includeDebitCardFee;
	
	const { data } = useGraphQL<GatewaysPublicRead_StorePaymentQueryVariables, GatewaysPublicRead_StorePaymentQuery>( {
		query    : GatewaysPublicRead_StorePayment,
		queryKey : [ 'gatewaysRead' ],
		variables: { options: { limit: 50, filter: { active: true, company: company.id } } },
	}, { keepPreviousData: true, enabled: Boolean( company.id ) } );
	
	const gatewayData = data?.gatewaysPublicRead;
	
	const cloverGateway = gatewayData?.find( ( gateway ) => gateway.external === 'CLOVER' );
	const showCardType = ( surchargeFeePercent || cardFeePercent ) && paymentMethod === 'card' && !includeDebitCardFee && !invoice?.metadata?.cardFee;
	
	const { data: paymentData } = useGraphQL<QueryClientPaymentsReadArgs>( {
		query    : clientPaymentsReadGQL,
		queryKey : [ 'clientPaymentsRead' ],
		variables: {
			options: {
				limit : 100,
				filter: { client: client?.id, order: null, status: 'OPEN', amount: { $gt: 0 } },
			},
		},
	}, { keepPreviousData: true, enabled: Boolean( client?.id ) } );
	
	const clientsPayments = paymentData?.clientPaymentsRead?.items;
	
	useAsyncEffect( async () => {
		try {
			if ( !client?.externalId || companyLocation?.gateway?.external !== 'CLOVER' ) return;
			await fetchCards( client?.id, setCards );
		} catch {
		}
		
	}, [ client?.id, client?.externalId ] );
	
	const { data: tenderData } = useQuery( [ 'getTenders' ], async () => {
		const { data } = await axios.post( '/api/processor/payment/getTenders', {
			id: gateway?.id,
		} );
		return data;
	}, { enabled: Boolean( gateway?.id ) && gateway?.external === 'CLOVER' && router.route.split( '/' )[ 1 ] !== 'p' } );
	
	const tenders = !isEmpty( tenderData?.elements ) ? tenderData.elements.filter( ( tender ) => {
		const loweredLabel = toLower( tender.label );
		return tender.visible
			&& tender.enabled
			&& ![
				'credit card',
				'card',
				'cash',
				'check',
				'debit card',
				'select customer',
				'invoice credit',
				'house account',
				'ach',
			].includes( loweredLabel );
	} ) : undefined;
	
	return (
		<Fragment>
			<RadioGroup
				value={paymentMethod}
				onChange={( e ) => {
					setCardType?.( '' );
					setSelectedClientPayment?.( null );
					if ( e.target.value && e.target.value.includes( 'saved' ) ) {
						const cardToken = e.target.value.split( '-' )?.[ 1 ];
						if ( cardToken ) {
							setCardId( cardToken );
						}
					}
					setPaymentMethod( e.target.value );
					if ( cloverGateway && e.target.value === 'card' ) {
						setPaymentGateway( gateway?.external === 'CLOVER' ? gateway : cloverGateway );
					} else {
						setPaymentGateway( gateway );
					}
					
				}}>
				{client && !metadata?.disablePayment && !isEmpty( cards ) && cards.map( ( card: any ) => (
					<FormControlLabel
						key={card.id}
						value={`saved-${card.token}`}
						control={<Radio/>}
						label={(
							<Fragment>
								Saved Card {card.last4 ? `(**** ${card.last4}) ${card?.expirationDate
									? `Exp: ${card.expirationDate?.substring( 0, 2 )}/${card.expirationDate.substring( 2 )}`
									: ''}` : ''}
								{surchargeFeePercent || cardFeePercent && !metadata?.enableCardFee && !invoice?.metadata?.cardFee
									? ` (${round( surchargeFeePercent || cardFeePercent, 2 )}% Charge)`
									: ''}
								<AsyncLoadingButton
									color='error'
									variant='text'
									onClick={async () => {
										const { data } = await axios.post( '/api/processor/payment/deleteCard', {
											id      : client.id,
											cardId  : card.id,
											sourceId: card.token,
										} );
										enqueueSnackbar( isString( data )
											? data
											: t( 'common:card-remove-success' ), { variant: 'success' } );
										await fetchCards( client.id, setCards );
									}}>
									Remove
								</AsyncLoadingButton>
							</Fragment>
						)}
						onClick={() => setCardId( card?.token )}
					/>
				) )}
				{( cloverGateway || [ 'CLOVER', 'CARD_CONNECT', 'STRIPE' ].includes( gateway?.external || '' ) ) && ( // card payment only for paying multiple HA invoices with ACCOUNT status
					<Fragment>
						<FormControlLabel
							value='card'
							control={<Radio/>}
							label={`${t( 'common:card' )}`}
						/>
						{showCardType
							? (
								<CardTypeOptions
									cardType={cardType}
									setCardType={setCardType}
									cardFeePercent={surchargeFeePercent || cardFeePercent}
								/>
							)
							: null}
					</Fragment>
				)}
				{!required && (
					<FormControlLabel
						value='cash'
						control={<Radio/>}
						label={t( 'common:cash' )}
					/>
				)}
				{!required && (
					<FormControlLabel
						value='check'
						control={<Radio/>}
						label={t( 'common:check' )}
					/>
				)}
				{!required && !isEmpty( clientsPayments ) && (
					<Fade in timeout={900}>
						<Box>
							<Stack direction='row' alignItems='center'>
								<FormControlLabel
									value='Previous payments'
									control={<Radio/>}
									label={t( 'common:payment' )}
								/>
								<Tooltip
									placement='right'
									title='Pay with unapplied payments'>
									<Chip
										label='Unallocated payments'
										color='primary'
										variant='alpha'
									/>
								</Tooltip>
							</Stack>
							<Collapse unmountOnExit in={paymentMethod === 'Previous payments'}>
								<ClientPaymentSelection
									totalRemaining={totalRemaining}
									clientPayments={clientsPayments}
									selectedClientPayment={selectedClientPayment}
									setSelectedClientPayment={setSelectedClientPayment}
								/>
							</Collapse>
						</Box>
					</Fade>
				)}
				{!required && tenders && [ ...type !== 'INVOICE'
					? tenders.filter( ( tender ) => toLower( tender.label ) !== 'invoiss' )
					: tenders ].map( ( tender, index ) => (
					<Fade key={index} in timeout={900}>
							<Stack
							direction='row'
							alignItems='center'
							onClick={( e ) => e.stopPropagation()}>
							<FormControlLabel
								value={toLower( tender.label )}
								control={<Radio/>}
								label={tender.label}
								/>
							<Tooltip
								placement='right'
								title={tender?.label === 'Invoiss'
									? t( 'commerce:use-invoiss-tender-to-charge' )
									: tender?.label
										? t( 'commerce:created-by-clover-dashboard' )
										: t( 'commerce:customer-tender' )}>
									<Chip
									label={tender?.label === 'Invoiss'
										? t( 'common:house-account' )
										: t( 'commerce:tender' )}
									color={tender?.label === 'Invoiss' ? 'primary' : 'default'}
									variant='alpha'
								/>
        </Tooltip>
						</Stack>
      </Fade>
				) )}
			</RadioGroup>
			<Stack spacing={1} direction='row' alignItems='center' mt={2}>
				{cancelMethod && (
					<Button
						sx={{ mr: 1 }}
						variant='outlined'
						startIcon={<ArrowBackIosIcon/>}
						onClick={cancelMethod}>
						{t( 'common:back' )}
					</Button>
				)}
				<AsyncLoadingButton
					disabled={!paymentMethod || showCardType && !cardType || paymentMethod === 'Previous payments' && !selectedClientPayment}
					variant='contained'
					color='primary'
					sx={{ width: 150 }}
					onClick={async () => await confirmMethod?.( {
						paymentMethod,
						paymentGateway,
						cardId,
						clientPaymentAmount: selectedClientPayment?.amount,
					} )}>
					{t( 'common:continue' )}
				</AsyncLoadingButton>
			</Stack>
		</Fragment>
	);
}
